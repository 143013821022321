@import "./../General.scss";

.message-container {
  margin: auto;
  width: 100%;
  max-width: 300px;
  white-space: pre-wrap;
  
  h2 {
    margin-bottom: 0px;
  }

  img {
    width: 4rem;
    height: auto;
    max-width: 200px;
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__body {
    display: flex;
    flex-direction: column;
    white-space: pre-line;
  }

  &__go-home {
    width: 80%;
  }

  &__bottom-container {
    margin-top: 40px;
  }
}
