.form {
  &__form {
    margin: 15px 10px;
    max-width: 450px;

    form {
      h3 {
        text-align: center;
      }

      & > div,
      .vehicle-part > div {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
      }

      .affiliation-category-input {
        margin-bottom: 20px;
      }

      .vehicle-close {
        width: 25px;
        padding: 0;
        float: right;
      }

      .MuiFormControl-root {
        & > div {
          max-width: 70vw;
        }
      }

      .MuiFormHelperText-root {
        text-align: center;
      }

      .hint {
        font-size: 12px;
        color: gray;
        margin: 0 0 10px;

        a {
          text-decoration: underline;
          color: cornflowerblue;
        }
      }

      .accept-toc-label {
        font-size: 10px;
      }

      .accept-toc-wrapper.Mui-disabled > span {
        color: #5ca95c !important;
      }

      .accept-toc-error {
        font-size: 10px;
        color: #d32f2f;
        margin: 0;
      }
    }

    &__submit {
      background-color: rgb(19, 138, 157) !important;
      margin: 15px 0 !important;
    }
  }
}